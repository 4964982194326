"use strict";
const defaultImage = require('@assets/icon/img-placeholder.svg');
(() => {
    const handleOnError = (image) => {
        console.warn(`Catch loadImage error | ${image.src}`);
        if (image.parentElement && image.parentElement instanceof HTMLPictureElement) {
            image.style.display = 'none';
            image.parentElement.classList.add('fallback');
        }
        else {
            image.removeAttribute('srcset');
            image.src = defaultImage;
            image.classList.add('fallback');
        }
    };
    const setupImageErrorHandler = (image) => {
        image.onerror = () => {
            handleOnError(image);
        };
        if (image.complete && image.naturalWidth === 0) {
            handleOnError(image);
        }
    };
    const getCloudFlareImages = (element) => {
        const images = Array.from(element.querySelectorAll('img'));
        return images.filter((img) => img.src.includes('cloudfront'));
    };
    getCloudFlareImages(document.body).forEach((image) => {
        setupImageErrorHandler(image);
    });
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    const newImages = Array.from(getCloudFlareImages(node));
                    newImages.forEach(setupImageErrorHandler);
                }
            });
        });
    });
    observer.observe(document.body, { childList: true, subtree: true });
})();
